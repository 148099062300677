export function formDate(data, time) {
  var date = new Date(data)
  if (time) {
    var date1 = new Date(time)
  }
  var year = date.getFullYear(),
    mon = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
    day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
    H = '',
    M = '',
    S = ''
  if (time) {
    ;(H = date1.getHours() < 10 ? '0' + date1.getHours() : date1.getHours()), (M = date1.getMinutes() < 10 ? '0' + date1.getMinutes() : date1.getMinutes()), (S = date1.getSeconds() < 10 ? '0' + date1.getSeconds() : date1.getSeconds())
  } else {
    ;(H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()), (M = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()), (S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  }
  return year + '-' + mon + '-' + day + ' ' + H + ':' + M + ':' + S
}

export function getParams(url) {
  let arr = url.split('/')
  return arr[arr.length - 1]
}
