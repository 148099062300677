<template>
  <div class="padd">
    <div class="addNews">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="资讯标题">
          <el-input v-model="form.title" placeholder="请输入资讯标题" style="margin-bottom: 10px"></el-input>
        </el-form-item>
        <el-form-item label="作者">
          <el-input v-model="form.author" placeholder="请输入作者" style="margin-bottom: 10px"></el-input>
        </el-form-item>
        <el-form-item label="封面图" class="upload-item">
          <el-upload class="avatar-uploader" action="https://upload-z1.qiniup.com" :show-file-list="false"
            :on-success="handleAvatarSuccess" :data="qiniuData">
            <img v-if="form.coverImg" :src="form.coverImg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="语言">
          <el-radio v-model="form.language" v-for="(item, i) in language" :key="i" :label="item.value"
            @change="handleLanguage">{{ item.label }}</el-radio>
        </el-form-item>
        <el-form-item label="内容简介">
          <el-input type="textarea" placeholder="请输入内容简介" v-model="form.brief" style="margin-bottom: 10px"></el-input>
        </el-form-item>
      </el-form>

      <div id="EditorQiniu"></div>
      <div id="div1"></div>
      <div class="save-btn">
        <el-button class="mbtn" type="primary" @click="saveNews">保 存</el-button>
        <el-button class="mbtn" type="info" @click="onBack">返 回</el-button>
        <el-button class="mbtn" type="success" @click="onPreview">预 览</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken, newsAdd, newsDetail, uploadImg } from '../serive/api'
import E from 'wangeditor'
const { BtnMenu } = E
export default {
  name: 'addNews',
  data() {
    return {
      form: {
        title: '',
        author: '',
        brief: '',
        content: '',
        coverImg: '',
        language: 0
      },
      id: 0,
      language: [
        { label: '中文', value: 0 },
        { label: '英文', value: 1 }
      ],
      qiniuData: {
        key: '',
        token: ''
      },
      upload_qiniu_url: 'https://upload-z1.qiniup.com',
      upload_qiniu_addr: 'https://qiniu.hanlll.com/'
    }
  },
  mounted() {
    // const menuKey = 'alertMenuKey'
    // const { $ } = E

    // 定义菜单
    class AlertMenu extends BtnMenu {
      constructor(editor) {
        // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
        const $elem = E.$(
          `<div class="w-e-menu" data-title="清除格式">
                <i class="el-icon-delete"></i>
            </div>`
        )
        super($elem, editor)
      }
      // 菜单点击事件
      clickHandler() {
        var editor = this.editor
        var str = editor.txt.html()
        str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, '')
        str = str.replace(/<style>[\s\S]*?<\/style>/gi, '')
        str = str.replace(/<\/?[^>]*>/g, '')
        str = str.replace(/[ | ]*\n/g, '\n')
        str = str.replace(/&nbsp;/gi, '')
        editor.txt.html(str)
      }
      tryChangeActive() {
        this.active()
      }
    }
    // 注册菜单
    const menuKey = 'alertMenuKey'

    // 注册菜单
    E.registerMenu(menuKey, AlertMenu)

    // const editor1 = new E('#div1')
    // editor1.create()
    const editor = new E(`#EditorQiniu`)
    // editor.menus.extend(menuKey, AlertMenu)
    //     editor.config.menus = [
    //     'head',
    //     'bold',
    //     'fontSize',
    //     'fontName',
    //     'italic',
    //     'underline',
    //     'strikeThrough',
    //     'indent',
    //     'lineHeight',
    //     'foreColor',
    //     'backColor',
    //     'link',
    //     'list',
    //     'todo',
    //     'justify',
    //     'quote',
    //     'emoticon',
    //     'image',
    //     'video',
    //     'table',
    //     'code',
    //     'splitLine',
    //     'undo',
    //     'redo',
    //     'clear'
    // ]
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.height = 500
    editor.config.showLinkImg = false
    editor.config.showLinkVideo = false
    editor.config.qiniu = true
    // editor.config.pasteFilterStyle = true
    // editor.config.pasteText= true
    editor.config.uploadImgMaxSize = 20000 * 1024 * 1024
    editor.config.uploadImgTimeout = 100000000
    editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      resultFiles.forEach(element => {
        this.uploadInit(element, insertImgFn)
      })
    }
    editor.config.onchange = newHtml => {
      this.editorData = newHtml
    }
    // 创建编辑器
    editor.create()
    this.editor = editor
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getInfo()
    }
    this.qiuToken()


    const params = localStorage.getItem('content');
    if (params) {
      this.form = JSON.parse(params)
      this.editor.txt.html(this.form.content)
    }
  },
  methods: {
    uploadInit(file, insertImgFn) {
      // getToken().then(res => {
      // 创建上传对象
      const formdata = new FormData()
      formdata.append('file', file)
      formdata.append('token', this.qiniuData.token)
      uploadImg(formdata).then(r => {
        console.log(r)
        console.log(r.data.key)
        let imgsrc = this.upload_qiniu_addr + r.data.key
        insertImgFn(imgsrc)
        // console.log(insertImgFn)
      })
      // })
    },
    qiuToken() {
      getToken().then(res => {
        if (res.status == 200) {
          if (res.data.code == 'OK') {
            this.qiniuData.token = res.data.result
            this.qiniuData.key = new Date().getTime() + '' + Math.floor(Math.random() * 1000)
          }
        }
      })
    },
    getInfo() {
      newsDetail({ id: this.id }).then(res => {
        if (res.status == 200) {
          if (res.data.code == 'OK') {
            this.form = res.data.result.info
            this.editor.txt.html(this.form.content)
          } else if (res.data.code == 9997 || res.data.code == 9998 || res.data.code == 9999) {
            this.$router.push('/login')
          }
        }
      })
    },
    handleLanguage(e) {
      console.log(e)
    },
    saveNews() {
      let { author, brief, coverImg, title, language } = this.form
      if (title === '') {
        this.$message.error('标题不能为空')
        return
      }
      if (brief === '') {
        this.$message.error('简介不能为空')
        return
      }
      let params = {
        author: author,
        brief: brief,
        content: this.editor.txt.html(),
        coverImg: coverImg,
        title: title,
        language: +language
      }
      if (this.id) {
        params.id = this.id
      }

      newsAdd(params).then(res => {
        if (res.status == 200) {
          if (res.data.code == 'OK') {
            this.$router.go(-1)
          } else if (res.data.code == 9997 || res.data.code == 9998 || res.data.code == 9999) {
            this.$router.push('/login')
          }
        }
      })
      localStorage.removeItem('content')
    },
    handleAvatarSuccess(res) {
      this.form.coverImg = this.upload_qiniu_addr + res.key
      this.qiniuData.key = new Date().getTime() + '' + Math.floor(Math.random() * 1000)
    },
    removeWordXml(text) {
      var html = text
      html = html.replace(/<\/?SPANYES[^>]*>/gi, '') //  Remove  all  SPAN  tags
      // html = html.replace(/<(\w[^>]*)  class=([^|>]*)([^>]*)/gi, "<$1$3");  //  Remove  Class  attributes
      // html = html.replace(/<(\w[^>]*)  style="([^"]*)"([^>]*)/gi, "<$1$3");  //  Remove  Style  attributes
      html = html.replace(/<(\w[^>]*) lang=([^|>]*)([^>]*)/gi, '<$1$3') //  Remove  Lang  attributes
      html = html.replace(/<\\?\?xml[^>]*>/gi, '') //  Remove  XML  elements  and  declarations
      html = html.replace(/<\/?\w+:[^>]*>/gi, '') //  Remove  Tags  with  XML  namespace  declarations:  <o:p></o:p>
      html = html.replace(/&nbsp;/, '') //  Replace  the  &nbsp;
      html = html.replace(/\n(\n)*( )*(\n)*\n/gi, '\n')
      //  Transform  <P>  to  <DIV>
      // var  re  =  new  RegExp("(<P)([^>]*>.*?)(<//P>)","gi")  ;            //  Different  because  of  a  IE  5.0  error
      //        html = html.replace(re, "<div$2</div>");
      return html
    },
    onEditorBlur() { },
    onBack() {
      // 清除localStorage
      localStorage.removeItem('content')
      this.$router.go(-1)
    },
    onPreview() {
      if (this.editor.txt.html() === '') {
        this.$message.error('预览内容不能为空')
        return
      }
      let { author, brief, coverImg, title, language } = this.form
      let params = {
        author: author,
        brief: brief,
        content: this.editor.txt.html(),
        coverImg: coverImg,
        title: title,
        language: +language
      }
      localStorage.setItem('content', JSON.stringify(params))
      this.$router.push({ name: 'PreviewNews', params: { content: this.editor.txt.html() } })
    }
  }
  // components: {
  //     quillEditor
  // }
}
</script>
<style scoped lang="less">
.addNews {
  margin-top: 20px;
}

.upload-item {
  margin-bottom: 0;
}

/deep/.w-e-toolbar .w-e-menu:nth-child(19) {
  display: none;
}

/deep/.ql-editor {
  height: 400px;
}

/deep/.avatar-uploader-icon {
  width: 150px !important;
  height: 150px !important;
  line-height: 150px !important;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: 4center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.save-btn {
  margin-top: 10px;
  text-align: center;
}

/deep/.el-textarea__inner {
  height: 60px;
}

.active_y {
  color: #5985ee;
  font-weight: 600;
}

.mbtn {
  margin: 0 20px;
}
</style>
