<template>
    <div class="pnews">
        <div class="news" v-html="content"></div>
        <div class="save-btn">
            <el-button type="info" @click="onBack">返 回</el-button>
        </div>
    </div>
</template>
<script>

export default {
    name: 'previewnews',
    data() {
        return {
            content: ''
        }
    },
    created() {
        this.content = this.$route.params.content
    },
    methods: {
        onBack() {
            this.$router.go(-1)
        }
    }
}
</script>
<style scoped lang="less">
.pnews {
    margin: 50px 490px;
    padding: 100px;
    background-color: white;
}

.save-btn {
    margin-top: 100px;
    text-align: center;
}
</style>
  