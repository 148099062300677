<template>
  <div class="padd">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="姓名" min-width="5%"> </el-table-column>
      <el-table-column prop="email" label="邮箱" min-width="15%"> </el-table-column>
      <el-table-column prop="title" label="标题" min-width="25%"> </el-table-column>
      <el-table-column prop="content" label="内容" min-width="45%"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="10%"> </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageSize" :current-page="pageIndex" :total="total" :page-sizes="[10, 20, 50, 100, 200, 300, 400]" layout="total, sizes, prev, pager, next, jumper"> </el-pagination>
    </div>
  </div>
</template>
<script>
import { concatList } from '../serive/api'
import { formDate } from '../utils/util'
export default {
  name: 'newsList',
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let params = {
        pagenum: this.pageIndex,
        pagesize: this.pageSize
      }
      concatList(params).then(res => {
        if (res.status == 200) {
          if (res.data.code == 'OK' && res.data.result.data) {
            let result = res.data.result
            result.data.map(item => {
              item.createTime = formDate(item.createTime)
            })
            this.tableData = result.data

            this.total = result.totalCounts
          } else if (res.data.code == 9997 || res.data.code == 9998 || res.data.code == 9999) {
            this.$router.push('/login')
          }
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getData()
    }
  }
}
</script>
<style>
.search {
  margin-top: 20px;
}

.el-table__header-wrapper {
  margin-top: 20px;
}
.page {
  margin-top: 20px;
  text-align: center;
}
</style>
