<template>
  <div class="padd">
    <div class="search">
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="coverImg" label="封面" min-width="5%">
        <template slot-scope="scope">
          <img :src="scope.row.coverImg" width="50" height="50" />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" min-width="20%"> </el-table-column>
      <el-table-column prop="brief" label="摘要" min-width="45%"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="15%"> </el-table-column>
      <el-table-column prop="address" label="操作" min-width="15%">
        <template slot-scope="scope">
          <el-button class="mop" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button class="mop" @click="handleDel(scope)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageSize"
        :current-page="pageIndex" :total="total" :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
        layout="total, sizes, prev, pager, next, jumper"> </el-pagination>
    </div>
  </div>
</template>
<script>
import { newsList, newsDel } from '../serive/api'
import { formDate } from '../utils/util'
export default {
  name: 'newsList',
  data() {
    return {
      tableData: [],
      title: '',
      keywords: '',
      pageIndex: 1,
      pageSize: 10,
      total: 0
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleSearch() {
      this.getData()
    },
    handleAdd() {
      localStorage.removeItem('content')
      this.$router.push({ path: 'addNews' })
    },
    getData() {
      let params = {
        pagenum: this.pageIndex,
        pagesize: this.pageSize
      }
      newsList(params).then(res => {
        if (res.status == 200) {
          if (res.data.code == 'OK' && res.data.result.data) {
            let result = res.data.result
            result.data.map(item => {
              item.createTime = formDate(item.createTime)
            })
            this.tableData = result.data

            this.total = result.totalCounts
          } else if (res.data.code == 9997 || res.data.code == 9998 || res.data.code == 9999) {
            this.$router.push('/login')
          }
        }
      })
    },
    handleEdit(elem) {
      this.$router.push({ path: 'addNews', query: { id: elem.id } })
    },
    handleDel(row) {
      if (confirm('确实要删除此条数据吗？')) {
        newsDel({ id: row.row.id }).then(res => {
          if (res.status == 200) {
            if (res.data.code == 'OK') {
              //    let index = this.tableData.filter(v => v.id = row.id)
              //    this.tableData.splice(row.$index,1)
              this.$router.go(0)
            }
          }
        })
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getData()
    }
  }
}
</script>
<style>
.search {
  margin-top: 20px;
}

.el-table__header-wrapper {
  margin-top: 20px;
}

.page {
  margin-top: 20px;
  text-align: center;
}

.mop {
  margin-right: 20px;
}
</style>
